import { useEffect, useRef } from "react";
import ListingTypeSelect from "../../components/Selectors/ListingTypeSelect";
import { addListingMeta, additionalAddFields } from "../../constants";
import useGlobalAddState from "../../stores/add";
import useGlobalConfigState from "../../stores/config";
import utils from "../../utils";
import { AddressModal } from "./AddressModal";
import { MapModal } from "./MapModal";

const AddListingItem = ({ prop }) => {
  const addState = useGlobalAddState();
  const configState = useGlobalConfigState();
  const wrapperRef = useRef(null);
  const form = addState.getUniversal("form");
  const errors = addState.getUniversal("errors");
  const config = configState.get("config");
  const meta =
    addListingMeta.find((m) => m.props.includes(prop)) ||
    addListingMeta.find((m) => m.default);
  const configItem = config.propLabels.find((pl) => pl.property === prop) || {};
  const label = configItem.label_ru || meta.labels?.[prop] || "";
  const addTypeConfig = configItem.add_type || "";
  const addType = meta.customAddType || addTypeConfig;
  const hasError = errors.includes(prop);

  const updateForm = (value) => {
    addState.mergeForm(value);
  };

  useEffect(() => {
    if (!!wrapperRef?.current) {
      const distance =
        wrapperRef.current.getBoundingClientRect().top + window.scrollY;
      const positionsObject = {
        [prop]: distance,
      };
      if (additionalAddFields[prop]?.length) {
        for (let f of additionalAddFields[prop]) {
          positionsObject[f] = distance;
        }
      }
      addState.mergeValue("yPositions", positionsObject);
    }
  }, [wrapperRef?.current]);

  const setForm =
    (value, target = false) =>
    () => {
      if (target) {
        value = value.target?.value;
      }
      addState.setUniversal(
        "errors",
        errors.filter((k) => k !== prop)
      );
      switch (addType) {
        case "range":
        case "string":
        case "multiple":
        case "multiline":
        case "address_string":
          updateForm({
            [prop]: addType !== "range" ? value : value.replace(/[^0-9.]/g, ""),
          });
          break;
        case "list":
        case "list-multiple":
          const isMultiple = addType === "list-multiple";
          let finalValue;
          if (isMultiple) {
            finalValue = form[prop]?.includes(value)
              ? form[prop].filter((i) => i !== value)
              : [...(form[prop] instanceof Array ? form[prop] : []), value];
          } else {
            finalValue = form[prop] === value ? "" : value;
          }
          updateForm({
            [prop]: finalValue,
          });
          break;
        default:
          return;
      }
    };

  const renderSwitch = () => {
    switch (addType) {
      case "listing-type-select":
        return <ListingTypeSelect formType='add' prop={prop} add />;
      case "multiline":
        return (
          <textarea
            className={`border border-[#D9D9D9] bg-white rounded-xl px-4 py-2`}
            value={form[prop] || ""}
            onChange={(e) => setForm(e, true)()}
            rows={5}
          />
        );
      case "range":
      case "string":
        return (
          <input
            className={`border border-[#D9D9D9] bg-white rounded-xl px-4 max-w py-2 ${
              addType === "address_string" ? "w-full md:w-1/2" : "max-w-40"
            }`}
            value={form[prop] || ""}
            disabled={prop === "seller_phone_number"}
            onChange={(e) => setForm(e, true)()}
          />
        );
      case "address_string":
        return (
          <div className='flex flex-col'>
            <div className='flex flex-col mb-3'>
              <AddressModal prop='kato_path' />
            </div>
            <div className='flex flex-col mb-3'>
              <AddressModal prop='residential_complex_name' />
            </div>
            <div className='flex flex-col mb-3'>
              <MapModal />
            </div>
            <div className='flex flex-col'>
              <p className={`font-bold mb-3`}>{label}</p>
              <input
                className={`border border-[#D9D9D9] bg-white rounded-xl px-4 max-w py-2 ${
                  addType === "address_string" ? "w-full md:w-1/2" : "max-w-40"
                }`}
                value={form[prop] || ""}
                onChange={(e) => setForm(e, true)()}
              />
            </div>
          </div>
        );
      case "list":
      case "list-multiple":
        const isMultiple = addType === "list-multiple";
        return (
          <div className='flex flex-row gap-y-4 flex-wrap overflow-x-scroll pb-2'>
            {config.propMapping[prop] &&
              config.propMapping[prop].list?.map((item) => {
                return (
                  <div
                    className={`
                                        cursor-pointer border border-touchable py-2 px-5 mr-2 rounded-xl whitespace-nowrap
                                        ${
                                          (
                                            isMultiple
                                              ? form[prop]?.includes(item.id)
                                              : form[prop] === item.id
                                          )
                                            ? "bg-touchable-selected text-primary"
                                            : "bg-white text-info-gray"
                                        }
                                    `}
                    onClick={setForm(item.id)}
                    style={
                      hasError
                        ? {
                            border: "1px solid #FF0000",
                          }
                        : {}
                    }
                  >
                    <span>{item.name}</span>
                  </div>
                );
              })}
          </div>
        );
      default:
        return null;
    }
  };

  return meta.exclude ? null : (
    <div
      ref={wrapperRef}
      className={`${meta.className} flex flex-col mb-3 ${
        hasError ? "input-parent-error" : ""
      }`}
    >
      {addType !== "address_string" && (
        <p className={`font-bold mb-3`}>{label}</p>
      )}
      {renderSwitch()}
    </div>
  );
};

export default AddListingItem;
