import useGlobalConfigState from "../../stores/config";
import areaIcon from "./sqrft.svg";
import numRoomsIcon from "./room.svg";
import numFloorsIcon from "./building.svg";
import call from "./call.svg";
import whatsapp from "./whatsapp.svg";
import whatsappGradient from "./whatsapp-gradient.svg";
import api from "../../api";
import { Link, useNavigate } from "react-router-dom";
import { renderHeader } from "../../helpers/listing";
import { searchResultMeta } from "../../constants";
import { useScreenSize } from "../../hooks/misc";
import { useState } from "react";
import noImage from "../../assets/no_image.png";
import { accessGlobalUserState } from "../../stores/user";

const { default: utils } = require("../../utils");

const icons = {
  area: areaIcon,
  floor: numFloorsIcon,
  num_rooms: numRoomsIcon,
};

const SearchResult = ({
  listing,
  firstImage = false,
  mode = "listing",
  parent = "Search",
  disabled = false,
  additionalClassName = "",
  showCalc = true,
}) => {
  const { width: screenWidth } = useScreenSize();
  const [showPhone, setShowPhone] = useState(false);
  const navigate = useNavigate();
  const configState = useGlobalConfigState();
  const config = configState.get();
  const meta = searchResultMeta[parent];
  let listingStructure = {};
  let listingType = listing.type;
  if (listing.type === "regular" && Boolean(listing.paid_button)) {
    listingType = "button";
  }
  if (listing.deal_type) {
    listingStructure = config.listingStructures.find(
      (obj) =>
        obj.deal_type == listing.deal_type &&
        obj.listing_type == listing.listing_type &&
        obj.property_type == listing.property_type
    );
  }
  let headerStructure;
  if (listingStructure && !!listingStructure.top && mode === "listing") {
    headerStructure = listingStructure.top.split(",");
  }
  const goToListing = () => {
    if (!disabled) {
      if (window.isNativeApp) {
        window.ReactNativeWebView?.postMessage(listing.id);
        return;
      }
      navigate(`/listings/${listing.id}`);
    }
  };

  const registerButtonsEvents = (type, item) => {
    utils.event(`${type}_from_${parent}`, item.id);
    utils.event(
      `${type === "phone" ? "call" : "whatsapp"}_button_clicked`,
      item.id,
      {
        price: item.price,
        deal_type: item.deal_type,
        listing_type: item.listing_type,
        property_type: item.property_type,
        rooms: item.num_rooms,
        area: item.area,
        address: item.address_string,
        source: parent,
        user_id: user.id,
      },
      "appsflyer"
    );
  };

  const onCallClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    registerEvents("phone", listing);
    if (screenWidth >= 640) {
      setShowPhone(true);
    } else {
      window.open(
        `tel:${listing.seller_phone_number.replace(/ /g, "")}`,
        "_self"
      );
    }
  };

  const onWhatsappClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    registerEvents("whatsapp", listing);
    window.open(
      `https://wa.me/${listing.seller_phone_number.replace(/ /g, "")}`,
      "_blank"
    );
  };

  const user = accessGlobalUserState().get();
  const registerEvents = (ls) => {
    utils.event(`listing_click_parent_${parent}`, ls.id);
    utils.event(
      "listing_details_viewed",
      ls.id,
      {
        price: ls.price,
        deal_type: ls.deal_type,
        listing_type: ls.listing_type,
        property_type: ls.property_type,
        rooms: ls.num_rooms,
        area: ls.area,
        address: ls.address_string,
        source: parent,
        user_id: user.id,
      },
      "appsflyer"
    );
  };

  return (
    <Link
      className={`grid grid-cols-6 mt-5 mb-10 cursor-pointer ${additionalClassName} listing-promotion-wrapper-${listingType}`}
      // onClick={goToListing}
      to={disabled ? "" : `/listings/${listing.id}`}
      onClick={() => registerEvents(listing)}
    >
      {!!listing.first_image ? (
        <div className={`${meta.imageClassName}`}>
          <div
            className='w-full h-full bg-center bg-cover bg-no-repeat rounded-lg min-h-[20vh] xl:min-h-0'
            style={{ backgroundImage: `url(${utils.getImageLink(listing)})` }}
          />
        </div>
      ) : (
        <div className={`${meta.imageClassName}`}>
          <div
            className='w-full h-full bg-center bg-cover bg-no-repeat rounded-lg min-h-[20vh] xl:min-h-0'
            style={{ backgroundImage: `url(${noImage})` }}
          />
        </div>
      )}
      <div className={`${meta.infoClassName} flex flex-col items-start`}>
        <p className='font-bold text-[28px] text-kilt-black mt-4'>
          {utils.formatPrice(listing?.price)} тг
        </p>
        <div className='flex flex-row'>
          {renderHeader(listing, headerStructure)}
        </div>
        <p className='mt-[6px] text-info-gray mb-3'>{listing.address_string}</p>
        {meta.hasDescription && (
          <p className='text-info-gray mb-3 hidden min-h-12 xl:line-clamp-2'>
            {listing.description || ""}
          </p>
        )}
        {meta.hasPhone && (
          <div className='flex flex-row w-full'>
            <a
              className={`mr-2 flex-grow xl:flex-grow-0 gradient-button py-2.5 px-6 rounded-xl flex flex-row items-center mb-2.5 justify-center listing-promotion-call-${listingType}`}
              onClick={onCallClick}
            >
              <img src={call} />
              <span className='text-sm font-bold text-white ml-1'>
                {!showPhone ? "Позвонить" : listing.seller_phone_number}
              </span>
            </a>
            <div
              className={`ml-2 flex-grow xl:flex-grow-0 flex flex-col whatsapp-button-wrapper-${listingType}`}
            >
              <a
                className={`border border-[#3244E4] py-2.5 px-6 rounded-xl flex flex-row items-center justify-center min-h-11`}
                onClick={onWhatsappClick}
              >
                <img
                  src={
                    ["top", "button"].includes(listingType)
                      ? whatsappGradient
                      : whatsapp
                  }
                />
                <span className='text-sm font-bold text-[#3244E4] ml-1'>
                  Написать
                </span>
              </a>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

export default SearchResult;
