import api from "../api";
import utils from "../utils";
import { accessGlobalEventsState } from "../stores/events";

const eventActions = {
  registerEvents: async () => {
    try {
      const body = {
        events: accessGlobalEventsState().getEvents(),
      };
      const res = await api.eventsApi.register(body);
      accessGlobalEventsState().clear();
    } catch (e) {
      console.log(e);
      return {};
    }
  },
};

export default eventActions;
