import { Suspense, useEffect, useRef, useState } from "react";
import useGlobalConfigState from "../../stores/config";
import useGlobalSearchState from "../../stores/search";
import filter from "./filter.svg";
import commonActions from "../../actions/common";
import api from "../../api";
import { OrbitProgress } from "react-loading-indicators";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import SearchResult from "./SearchResult";
import Modal from "../../components/Modal/Modal";
import close from "./close.svg";
import FilterItem from "./FilterItem";
import GradientButton from "../../components/Buttons/GradientButton";
import QuickFilter from "./QuickFilter.";
import { updateResults } from "../../actions/search";
import { dealTypes, listingTypes } from "../../constants";
import ListingTypeSelect from "../../components/Selectors/ListingTypeSelect";
import utils from "../../utils";
import AddressSelector from "../../components/Address/AddressSelector";
import { useGlobalMiscState } from "../../stores/misc";
import { useResultsCount } from "../../hooks/search";
import useGlobalKatoState from "../../stores/kato";

const quickFiltersBase = ["deal_type"];
const quickFiltersDefault = [
  "deal_type",
  "listing_type",
  "furniture",
  "num_rooms",
];
const labels = [
  {
    property: "deal_type",
    label_ru: "Тип сделки",
    variants: ["Арендовать", "Купить"],
  },
  {
    property: "listing_type",
    label_ru: "Тип жилья",
    variants: ["Жилая", "Коммерческая"],
  },
];
const sortValues = ["default", "new", "price_asc", "price_desc"];
const sortLabels = {
  default: "Популярные",
  new: "Новые предложения",
  price_asc: "Цена по возрастанию",
  price_desc: "Цена по убыванию",
};
const filterProps = ["num_rooms", "price", "kato_path"];

const Search = ({}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const searchState = useGlobalSearchState();
  const searchStateSelf = searchState.getState();
  const configState = useGlobalConfigState();
  const miscState = useGlobalMiscState();
  const searchWrapperRef = useRef();
  const config = configState.get();
  const form = searchState.getValue("form");
  const loading = searchState.getValue("loading");
  const counter = searchState.getValue("counter");
  const page = searchState.getValue("offset");
  const sorting = searchState.getValue("sorting");
  const total = searchState.getValue("total");
  const results = searchState.getResults();
  const advancedConfig = config.advanced || {};
  const katoState = useGlobalKatoState();
  const adFrequency1 = advancedConfig.ad_frequency_1
    ? parseInt(advancedConfig.ad_frequency_1)
    : 3;
  const adFrequency2 = advancedConfig.ad_frequency_2
    ? parseInt(advancedConfig.ad_frequency_2)
    : 5;
  const quickFiltersConfig = advancedConfig[
    `quick-filters-${form.deal_type}-${form.listing_type}-${form.property_type}`
  ]
    ? advancedConfig[
        `quick-filters-${form.deal_type}-${form.listing_type}-${form.property_type}`
      ].split(",")
    : [];
  const quickFilters = [...quickFiltersBase, ...quickFiltersConfig];
  const configLoaded = "propLabels" in config;
  const { loading: resultsCountLoading, count: resultsCount } =
    useResultsCount();
  const [isLoading, setIsLoading] = useState(false);
  const [prevResultsCount, setPrevResultsCount] = useState(null);

  let filteredPt;
  if (config && config.propertyTypes) {
    filteredPt = config.propertyTypes.filter((pt) => {
      return (
        pt.listing_type === form.listing_type &&
        !!config.listingStructures.find(
          (ls) =>
            form.deal_type === ls.deal_type &&
            pt.id === ls.property_type &&
            pt.listing_type === ls.listing_type
        )
      );
    });
  }

  const onScroll = (state) => async () => {
    if (searchWrapperRef.current) {
      const isNearBottom =
        searchWrapperRef.current?.getBoundingClientRect().bottom -
        window.innerHeight;
      if (
        isNearBottom <= 200 &&
        results?.length < state.getValue("total") &&
        !state.getValue("loading") &&
        prevResultsCount != -1 &&
        !isLoading
      ) {
        setIsLoading(true);
        state.nextPage(Date.now());
        updateResults(false);
      }
    }
  };

  useEffect(() => {
    updateResults(false);
  }, [page]);

  useEffect(() => {
    const listener = onScroll(searchState);
    document.addEventListener("scroll", listener);

    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, []);

  useEffect(() => {
    searchState.clear();
    updateResults();
  }, []);

  const clearFilters = () => {
    const structure = config.listingStructures.find((ls) => {
      return (
        form.deal_type === ls.deal_type &&
        form.listing_type === ls.listing_type &&
        form.property_type === ls.property_type
      );
    });
    katoState.reset();
    if (structure) {
      const propsList = structure.props.split(",");
      const obj = {};
      for (let prop of propsList) {
        obj[prop] = "";
      }

      searchState.setUniversal("form", {
        deal_type: form.deal_type,
        listing_type: form.listing_type,
        property_type: form.property_type,
        ...obj,
      });
    }
  };

  useEffect(() => {
    if (config && config.listingStructures) {
      const structure = config.listingStructures.find((ls) => {
        return (
          form.deal_type === ls.deal_type &&
          form.listing_type === ls.listing_type &&
          form.property_type === ls.property_type
        );
      });
      if (structure) {
        const propsList = structure.props.split(",");
        const obj = {};
        for (let prop of propsList) {
          obj[prop] = "";
        }
        const currentFormKeys = Object.keys(form),
          objKeys = Object.keys(obj),
          keepKeys = currentFormKeys.filter((k) => objKeys.includes(k));
        const keepObject = {};
        for (let k of keepKeys) {
          keepObject[k] = form[k];
        }
        keepObject.status = form.status;
        keepObject.user_type = form.user_type;
        if (form.kato_path?.length) {
          keepObject.kato_path = utils.shallowCopy(form.kato_path);
        }
        if (form.residential_complex?.length) {
          keepObject.residential_complex = utils.shallowCopy(
            form.residential_complex
          );
        }
        searchState.setUniversal("form", {
          deal_type: form.deal_type,
          listing_type: form.listing_type,
          property_type: form.property_type,
          ...obj,
          ...utils.shallowCopy(keepObject),
        });
      } else {
        searchState.setUniversal("form", {
          deal_type: form.deal_type,
          listing_type: form.listing_type,
          property_type: form.property_type,
          status: 1,
          user_type: "",
        });
      }
    }
  }, [
    configState.config,
    form.deal_type,
    form.listing_type,
    form.property_type,
  ]);

  const search = async () => {
    if (prevResultsCount == -1) {
      return;
    } else {
      console.log("prevResultsCount: " + prevResultsCount);
    }
    searchState.setLoading(true);
    const body = {
      filters: {},
      config: {},
      page,
      sorting,
      source: "web",
    };
    for (let finalKey of Object.keys(form).filter(
      (key) =>
        form[key] !== "" &&
        (!(form[key] instanceof Array) ||
          (form[key] instanceof Array && form[key]?.length > 0))
    )) {
      if (finalKey === "price") {
        const obj = {};
        for (let key of Object.keys(form[finalKey])) {
          obj[key] = form[finalKey][key].replace(/ /g, "");
        }
        body.filters[finalKey] = obj;
      } else {
        body.filters[finalKey] = form[finalKey];
      }
    }
    const configKeys = Object.keys(form).filter(
      (key) => !["deal_type", "listing_type", "property_type"].includes(key)
    );
    for (let cKey of configKeys) {
      body.config[cKey] =
        config.propLabels.find((pl) => pl.property === cKey)?.filter_type || "";
    }

    const resp = await commonActions.simpleGetter(api.listingApi.search, body);
    if (resp.list.length === 0 || resp.list.length < 10) {
      setPrevResultsCount(-1);
    } else {
      console.log(`list lng${resp.list.length}`);
      setPrevResultsCount(1);
    }
    searchState.setUniversal("total", resp.total);
    const finalList = [];
    if (resp?.list && resp?.list.length) {
      for (let [i, item] of resp?.list.entries()) {
        finalList.push({
          type: "listing",
          item,
        });
        if ((i + 1) % adFrequency2 === adFrequency1) {
          const propIndex = page % 4 === 1 ? 0 : 1;
          if (
            page === 0 &&
            !(form[filterProps[2]] || form.residential_complex)
          ) {
            finalList.push({
              type: "filter",
              index: 2,
            });
          } else if (
            (i + 1 + (page % 2) * 10) % (adFrequency1 + 10) === 0 &&
            !form[filterProps[propIndex]]
          ) {
            finalList.push({
              type: "filter",
              index: propIndex,
            });
          } else {
            finalList.push({
              type: "new-buildings",
            });
          }
        }
        if ((i + 1) % adFrequency2 === 0) {
          finalList.push({
            type: "new-buildings",
          });
        }
      }
    }
    if (page == 0) {
      searchState.setResults(finalList);
    } else {
      searchState.addResults(finalList);
    }
    searchState.setLoading(false);
  };

  useEffect(() => {
    if (!loading && !!counter && configLoaded) {
      search();
    }
  }, [counter, configLoaded]);

  useEffect(() => {
    search();
  }, []);

  const renderSwitch = (res) => {
    switch (res.type) {
      case "listing":
        return <SearchResult listing={res.item} />;
      default:
        return null;
    }
  };

  const searchFilter = () => {
    setFiltersOpen(false);
    updateResults();
  };

  return (
    <div className='lg:container grid grid-cols-12 '>
      <div className='col-span-12 py-5 flex flex-row border-t border-b border-[#F2F2F2] overflow-x-scroll xl:overflow-x-visible sticky top-0 bg-white z-10'>
        <div
          className='bg-kilt-gray rounded-xl py-[5px] px-[6px] ml-4 xl:ml-0 xl:py-2 xl:px-2.5 mr-2 cursor-pointer min-w-8'
          onClick={() => setFiltersOpen(true)}
        >
          <img className='w-[21px] h-[21px]' src={filter} />
        </div>
        {quickFilters.map((q, index) => {
          let isDefaultLabel = true,
            label =
              config.propLabels?.find((pl) => pl.property === q)?.label_ru ||
              labels.find((pl) => pl.property === q)?.label_ru ||
              "";
          if (q === "new_building") {
            label = "Новостройки";
          }
          if (form[q] && form[q] != "") {
            isDefaultLabel = false;
            if (["deal_type", "listing_type"].includes(q)) {
              label = labels.find((l) => l.property === q)?.variants[
                form[q] - 1
              ];
            } else if (q === "property_type") {
              label = filteredPt.find((f) => f.id === form[q])?.label_ru;
            } else if (
              config.propMapping &&
              config.propMapping[q] &&
              config.propMapping[q].list
            ) {
              if (form[q] instanceof Array) {
                label = [...form[q]]
                  .sort((a, b) => {
                    const ids = config.propMapping[q].list?.map(
                      (item) => item.id
                    );
                    return ids.indexOf(a) - ids.indexOf(b);
                  })
                  .map((value) => {
                    let returnVal =
                      config.propMapping[q].list.find(
                        (item) => item.id === value
                      )?.name || "";
                    if (q === "num_rooms" && returnVal === 5) {
                      returnVal = "5+";
                    }
                    return returnVal;
                  })
                  .join(" / ");
              } else {
                label = config.propMapping[q].list.find(
                  (item) => item.id === form[q]
                ).name;
              }
            }
          }
          return <QuickFilter label={label} type={q} key={q} />;
        })}
      </div>
      <div
        className='col-span-12 grid grid-cols-12 search-wrapper'
        ref={searchWrapperRef}
      >
        <LoadingWrapper
          loading={loading && page === 0}
          render={
            <div className='col-span-12 flex flex-row justify-center mt-4'>
              <OrbitProgress size='small' color='#3F4FE0' />
            </div>
          }
        >
          {results.map((res, i) => (
            <div
              className='col-span-12'
              key={JSON.stringify(res.item) + "-" + i}
            >
              {renderSwitch(res)}
            </div>
          ))}
          {prevResultsCount != -1 && loading && page !== 0 && (
            <div className='col-span-12 flex flex-row justify-center mt-4'>
              <OrbitProgress size='small' color='#3F4FE0' />
            </div>
          )}
          {results?.filter((i) => i.type === "listing").length >=
            prevResultsCount &&
          !loading &&
          page !== 0 ? (
            <div
              className='col-span-12 flex flex-col justify-center center'
              style={{ alignItems: "center" }}
            >
              <div style={{ marginTop: 8, fontSize: 20, fontWeight: "600" }}>
                Мы всё показали! 🔍
              </div>
              <div
                style={{
                  marginTop: 8,
                  fontSize: 14,
                  fontWeight: "400",
                  marginBottom: 20,
                }}
              >
                Попробуйте другие параметры поиска, чтобы увидеть больше
                вариантов.
              </div>
            </div>
          ) : null}
        </LoadingWrapper>
      </div>
      <Modal
        dimensions='w-full h-full xl:w-4/5 xl:h-4/5 overflow-y-scroll'
        visible={filtersOpen}
        setVisible={setFiltersOpen}
      >
        <div className='flex flex-col relative'>
          <div className='flex flex-row justify-between pt-5 px-4'>
            <img src={close} onClick={() => setFiltersOpen(false)} />
            <p className='font-bold text-typography'>Фильтр</p>
            <p
              className='text-kilt-dark-gray'
              style={{ cursor: "pointer" }}
              onClick={clearFilters}
            >
              Стереть
            </p>
          </div>
          <div className='grid grid-cols-12 mt-4'>
            <div className='col-span-2 mb-3 hidden xl:block'>
              <ListingTypeSelect prop='deal_type' />
            </div>
            <div className='col-span-2 mb-3 pl-2 hidden xl:block'>
              <ListingTypeSelect prop='listing_type' />
            </div>
            <div className='col-span-2 mb-3 pl-2 hidden xl:block'>
              <ListingTypeSelect prop='property_type' />
            </div>
            <div className='col-span-12 block xl:hidden'>
              <FilterItem type='deal_type' />
            </div>
            <div className='col-span-12 my-5 block xl:hidden'>
              <FilterItem type='listing_type' />
            </div>
            <div className='col-span-12 px-4 block xl:hidden'>
              <FilterItem type='property_type' />
            </div>

            <AddressSelector
              // resetKato={resetKato}
              wrapperClassName='col-span-12 xl:col-span-6 my-4 xl:my-0 flex flex-row items-center justify-center cursor-pointer px-8'
            />
            <div className='col-span-12 border-t border-[#F2F2F2] py-2 px-4'>
              <div className='flex flex-row items-center py-2'>
                <input
                  type='checkbox'
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: 20,
                  }}
                  value={form.user_type}
                  onChange={(target) =>
                    searchState.mergeForm({
                      ["user_type"]: target.target.checked ? 1 : 2,
                    })
                  }
                  id='chb'
                />
                <label htmlFor='chb' className='text-typography'>
                  От собственников недвижимости
                </label>
              </div>
            </div>
            {Object.keys(form)
              .filter(
                (key) =>
                  ![
                    "deal_type",
                    "listing_type",
                    "property_type",
                    "kato_path",
                    "new_building_id",
                    "status",
                    "user_type",
                    "residential_complex",
                  ].includes(key)
              )
              .map((prop) => (
                <div
                  className='col-span-12 border-t border-[#F2F2F2] pt-4 px-4'
                  key={prop}
                >
                  <p className='font-bold text-lg text-typography'>
                    {config.propLabels?.find((pl) => pl.property === prop)
                      ?.label_ru || ""}
                  </p>
                  <FilterItem type={prop} />
                </div>
              ))}
          </div>
          <GradientButton
            hasLoading
            loading={resultsCountLoading}
            onClick={searchFilter}
            text={`Показать ${
              utils.formatPrice(resultsCount) || 0
            } результатов`}
            additionalClass='sticky bottom-3 right-3 self-end search-res-count-button'
            loadingStyle={{
              width: 20,
              height: 20,
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Search;
