import React, { useEffect } from "react";
import cl from "./AgencyModeration.module.css";
import Modal from "../../components/Modal/Modal";
import api from "../../api";
import axios from "axios";

export const AgencyModeration = () => {
  const [agencies, setAgencies] = React.useState([]);
  const [selectedAgency, setSelectedAgency] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [showMessageModal, setShowMessageModal] = React.useState(false);

  const sendDecline = async () => {
    const data = await axios.post(
      `${api.serverUrl}/api/v1/users/decline-agency-request`,
      {
        id: selectedAgency?.id,
        message: message,
      }
    );
    setShowModal(false);
    setShowMessageModal(false);
    loadData();
  };
  const sendApprove = async () => {
    const data = await axios.post(
      `${api.serverUrl}/api/v1/users/approve-agency-request`,
      {
        id: selectedAgency?.id,
      }
    );
    setShowModal(false);
    setShowMessageModal(false);
    loadData();
  };

  const loadData = async () => {
    const data = await axios.get(
      `${api.serverUrl}/api/v1/users/agencies-to-verify`
    );
    setAgencies(data.data.agencies);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <React.Fragment>
        {agencies.map((partner) => (
          <div
            className={cl.partnerCard}
            onClick={() => {
              setShowModal(true);
              setSelectedAgency(partner);
            }}
            key={partner?.id}
          >
            <div className={cl.partnerCardIcon}>
              <img
                src={`${api.serverUrl}/uploads/${partner?.user?.photo}`}
                alt=''
                className={cl.cardImg}
              />
            </div>
            <div className={cl.partnerCardText}>
              <div className={cl.partnerName}>{partner?.user?.firstname}</div>
              <div className={cl.partnerCardSubtitle}>
                Заявка создана/отредактирована{" "}
                {new Date(partner?.updatedAt).toLocaleDateString()}{" "}
                {new Date(partner?.updatedAt).toLocaleTimeString()}
              </div>
            </div>
          </div>
        ))}
        <Modal
          dimensions='w-full h-full xl:w-4/5 xl:h-[80%] overflow-y-scroll rounded-md'
          visible={showModal}
          setVisible={setShowModal}
        >
          <div className={cl.wrapper}>
            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Фото</label>
                <img
                  src={`${api.serverUrl}/uploads/${selectedAgency?.user?.photo}`}
                  alt=''
                  className={cl.cardImg}
                />
              </div>
            </div>
            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>ID</label>
                <input
                  disabled={true}
                  className={cl.inputText}
                  type='text'
                  value={selectedAgency?.user?.id}
                />
              </div>
            </div>
            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Название</label>
                <input
                  disabled={true}
                  className={cl.inputText}
                  type='text'
                  value={selectedAgency?.user?.firstname}
                />
              </div>
            </div>
            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Номер телефона</label>
                <input
                  disabled={true}
                  className={cl.inputText}
                  type='text'
                  value={selectedAgency?.user?.phone}
                />
              </div>
            </div>
            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Полный адрес</label>
                <input
                  disabled={true}
                  className={cl.inputText}
                  type='text'
                  value={selectedAgency?.user?.agent_full_address}
                />
              </div>
            </div>
            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>График работы</label>
                <input
                  disabled={true}
                  className={cl.inputText}
                  type='text'
                  value={selectedAgency?.user?.agent_working_hours}
                />
              </div>
            </div>
            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Город</label>
                <input
                  disabled={true}
                  className={cl.inputText}
                  type='text'
                  value={selectedAgency?.user?.agent_city}
                />
              </div>
            </div>
            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Описание</label>
                <input
                  disabled={true}
                  className={cl.inputText}
                  type='text'
                  value={selectedAgency?.user?.agent_about}
                />
              </div>
            </div>
            <div className={cl.inputView}>
              <label>Файлы</label>
              <div className={cl.input}>
                {selectedAgency &&
                  Object.keys(selectedAgency?.meta).map((key) =>
                    selectedAgency?.meta[key].map((file) => (
                      <div>
                        <a
                          className={cl.download}
                          href={`https://kiltapp.kz/uploads/${file}`}
                          target='__blank'
                        >
                          {key}
                        </a>
                      </div>
                    ))
                  )}
              </div>
            </div>
            <div className={cl.buttons}>
              <button
                title='Принять'
                className={cl.btnYes}
                onClick={sendApprove}
              >
                Принять
              </button>
              <button
                title='Отклонить'
                onClick={() => setShowMessageModal(true)}
                className={cl.btnNo}
              >
                Отклонить
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          dimensions='w-1/2 h-1/2 bg-white rounded-md'
          visible={showMessageModal}
          setVisible={setShowMessageModal}
        >
          <div className={cl.modalWrapper}>
            <div className={cl.modalSubtitle}>Отклонение заявки</div>
            <textarea
              className={cl.textarea}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              placeholder='Опишите причину отклонения заявки...'
            ></textarea>
            <button
              title='Отклонить'
              onClick={sendDecline}
              className={cl.btnNo}
            >
              Отклонить
            </button>
          </div>
        </Modal>
      </React.Fragment>
    </div>
  );
};
