import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/Home/Home";
import Root from "./screens/Root/Root";
import { useEffect } from "react";
import useGlobalConfigState from "./stores/config";
import commonActions from "./actions/common";
import api from "./api";
import Search from "./screens/Search/Search";
import Listing from "./screens/Listing/Listing";
import AddListing from "./screens/AddListing/AddListing";
import Profile from "./screens/Profile/Profile";
import Partner from "./screens/Partner/Partner";
import BlogSingle from "./screens/Blog/BlogSingle";
import { Moderation } from "./screens/Moderation/Moderation";
import { ResedentialComplex } from "./screens/ResedentialComplex/ResedentialComplex";
import { DeleteAccountInfo } from "./screens/FAQ/DeleteAccountInfo";
import { SearchWebView } from "./screens/Search/SearchWebView";
import { AddListingMap } from "./screens/Map/AddListingMap";
import { Favorites } from "./screens/Favorites/Favorites";
import { accessGlobalEventsState } from "./stores/events";
import eventActions from "./actions/event";

function App() {
  const configState = useGlobalConfigState();
  useEffect(() => {
    const fetchConfig = async () => {
      const res = await commonActions.simpleGetter(api.listingApi.getConfig);
      const config = {
        ...res,
      };
      configState.setConfig(config);
    };
    fetchConfig();
  }, []);

  useEffect(() => {
    // alert("statsInterval");
    const statsInterval = setInterval(() => {
      const now = new Date();
      const seconds = now.getSeconds();
      if (seconds % 10 === 0) {
        // alert("sent");
        if (accessGlobalEventsState().getEvents()?.length) {
          eventActions.registerEvents();
        }
      }
    }, 990);
    return () => {
      clearInterval(statsInterval);
    };
  }, []);

  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route path='/' element={<Root />}>
          <Route path='/' element={<Home />} />
          <Route path='/search' element={<Search />} />
          <Route path='/listings/:listingId' element={<Listing />} />
          <Route path='/add-listing' element={<AddListing />} />
          <Route
            path='/edit-listing/:id'
            element={<AddListing mode='edit' />}
          />
          <Route path='/profile' element={<Profile />} />
          <Route path='/profile/:tab' element={<Profile />} />
          <Route path='/partner' element={<Partner />} />
          <Route path='/partner/:tab' element={<Partner />} />
          <Route path='/moderation' element={<Moderation />} />
          <Route path='/moderation/:tab' element={<Moderation />} />
          <Route path='/blog/:id' element={<BlogSingle />} />
          <Route path='/residential/:id' element={<ResedentialComplex />} />
          <Route path='/faq/delete-account' element={<DeleteAccountInfo />} />
          <Route path='/favorites' element={<Favorites />} />
          <Route
            path='*'
            element={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                  fontSize: "2rem",
                }}
              >
                <div>Тут ничего нет :(</div>
                <div>
                  <a
                    href='/'
                    className='text-blue-500
                  hover:text-blue-700
                  cursor-pointer
                  text-decoration-underline
                  '
                  >
                    На главную
                  </a>
                </div>
              </div>
            }
          />
        </Route>
        <Route path='/search-2gis' element={<SearchWebView />} />
        <Route path='/add-listing-map' element={<AddListingMap />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
